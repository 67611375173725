import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
import OwlCarousel from 'react-owl-carousel';  
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";




import swal from 'sweetalert';
// import Loader from "react-loader-spinner";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { Container, Card, Row, Col } from "react-bootstrap";
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import logo1 from '../assets/images/logo1.png';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import KIT1 from '../assets/images/KIT.jpg';
// import slid2 from '../assets/images/slid2.webp';


// import ch1 from '../asset1/images/demos/demo-26/banners/banner-5.jpg';
// import ch2 from '../asset1/images/demos/demo-26/banners/banner-6.jpg';
// import ch3 from '../asset1/images/demos/demo-26/bg-1.jpg';

import slid1 from '../assets/images/slider (1).jpg';
import slid2 from '../assets/images/slider (2).jpg';
import slid3 from '../assets/images/slider (3).jpg';
import slid4 from '../assets/images/slider (4).jpg';
import slid5 from '../assets/images/slider (5).jpg';
import slid6 from '../assets/images/slider6.jpg';


import ch344 from '../assets/images/KIT2.jpg';
import ch3444 from '../assets/images/KIT3.jpg';
// import ch4 from '../asset1/images/demos/demo-14/slider/slide-2.jpg';
// import ch5 from '../asset1/images/demos/demo-26/banners/banner-3.jpg';
// import ch6 from '../asset1/images/demos/demo-26/banners/banner-4.jpg';
import Carousel1 from './Carosel';
import Carousel2 from './Carosel1';
import Carousel3 from './Carosel2';
import Carousel4 from './Carosel3';
import Carousel5 from './Carosel4';
import Carousel6 from './Carosel5';
import Carousel7 from './Carosel6';
import Carousel8 from './Carosel7';
import Ico from './iconBox';
import Cate from './Cate';

// import "react-multi-carousel/lib/styles.css";





const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};






const Home = ( props ) => {
    const  responsive= {
      0: {
          items: 1,
      },
      450: {
          items: 2,
      },
      600: {
          items: 3,
      },
      1000: {
          items: 4,
      },
  }
    const  responsive1= {
      0: {
          items: 2,
      },
      450: {
          items: 2,
      },
      600: {
          items: 3,
      },
      750: {
          items: 4,
      },
      1000: {
          items: 7,
      },
  }
    const  responsive2= {
      0: {
          items: 2,
      },
     
      450: {
          items: 3,
      },
      600: {
          items: 4,
      },
      1000: {
          items: 6,
      },
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const options = {
    // margin: 30,
    // responsiveClass: true,
    // nav: true,
    // dots: false,
    // autoplay: false,
    // navText: ["Prev", "Next"],
    // smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 4,
        },
        1600: {
            items: 4,

        }
    },
};
//     const [options , setoptions] = useState( {
//     loop: true,
//     margin: 10,
//     nav: true,
//     responsive: {
//         0: {
//             items: 1,
//         },
//         600: {
//             items: 3,
//         },
//         1000: {
//             items: 4,
//         },
//     },
// })
    const [Categories , setCategories] = useState([])
    const [Categories1 , setCategories1] = useState([])
    const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [product1 , setproduct1] = useState([])
    const [product8 , setproduct8] = useState([])
    const [product9 , setproduct9] = useState([])
    const [product10 , setproduct10] = useState([])
    const [product11 , setproduct11] = useState([])
    const [UserDatat , setUserDatat] = useState({})
    const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [ArrivalPhoto , setArrivalPhoto] = useState([])
    const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [SubClassCategories1 , setSubClassCategories1] = useState([])
    const [SubClassCategories2 , setSubClassCategories2] = useState([])
    const [DataPart2 , setDataPart2] = useState([])
    const [fvalue , setfvalue] = useState([])
    const [data, setdata] = useState([]) 
    const [companieswith , setcompanieswith] = useState([])
    const [couponShow , setcouponShow] = useState(true)
    const [chk1 , setchk1] = useState(true)
    const [chk2 , setchk2] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])

    const [Ite1 , setIte1] = useState(1)
    const [Ite2 , setIte2] = useState(1)

    const [fg , setfg] = useState(true)
    // var settings = {
    //   infinite: true,
    //   dots: true,
    //   slidesToShow: 5,
    //   slidesToScroll: 1,
    //   lazyLoad: true,
    //   autoplay: true,
    // autoplaySpeed: 2000,
    // };
    useEffect(() =>{

        window.scrollTo(0, 0)
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
        }, 1000);
        
      // document.getElementsByClassName("carousel-indicators")
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[0].remove()
      // document.getElementsByClassName("visually-hidden")[1].remove()
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
        fetch("https://garden-oasis-landscapping-main-back.vercel.app/slider-photo",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res1=>{
            console.log(res1[0]);
            setSliderPhoto(res1)
          })
          // carrouselInit()
      //   fetch("https://garden-oasis-landscapping-main-back.vercel.app/companieswith",{
      //     method: "GET",
      //      headers :  {
      //      "Content-Type" : "application/json" , 
      //  } ,
      // })
      // .then(res9=>res9.json())
      // .then(res11=>{
      //     console.log(res11[0]);
      //     setcompanieswith(res11)
      
      // })
        // fetch("https://garden-oasis-landscapping-main-back.vercel.app/BannerPhoto",{
        //     method: "GET",
        //      headers :  {
        //      "Content-Type" : "application/json" , 
        //  } ,
        // })
        // .then(res22=>res22.json())
        // .then(res222=>{
        //     console.log(res222[0]);
        //     setBannerPhoto(res222)
        
        // })

        // fetch("https://garden-oasis-landscapping-main-back.vercel.app/arrival-photo",{
        //     method: "GET",
        //      headers :  {
        //      "Content-Type" : "application/json" , 
        //  } ,
        // })
        // .then(res2=>res2.json())
        // .then(res3=>{
        //     console.log(res3);
        //     setArrivalPhoto(res3)
        
        // })
       

//         fetch("https://garden-oasis-landscapping-main-back.vercel.app/CourseAllSubCategories",{
//             method: "GET",
//              headers :  {
//              "Content-Type" : "application/json" , 
//          } ,
//         })
//         .then(res2=>res2.json())
//         .then(res3=>{
//             let data = res3.sort((a, b) => {
//                 return a.sort - b.sort;
//             });
//             // console.log(data);
//             setSubClassCategories(data)


//             fetch("https://garden-oasis-landscapping-main-back.vercel.app/AllCategories",{
//             method: "GET",
//              headers :  {
//              "Content-Type" : "application/json" , 
//          } ,
//         })
//         .then(res12=>res12.json())
//         .then(res13=>{
//           setSubClassCategories2(res13)
//           let a = []
//           console.log(res3,res13)
//           for (let i = 0; i < res13.length; i++) {
//           for (let j = 0; j < res3.length; j++) {
//             console.log(res3[j].MainCategories , res13[i].MainCategories , res3[j].CategoriesName , res13[i].CategoriesName )
//             if(res3[j].MainCategories === res13[i].MainCategories && res3[j].CategoriesName === res13[i].CategoriesName ){
//               // a.push("1")
// console.log(i,j)
//               a[i] = "1"
//               break
//             }
//             else{
//               a[i] = "0"
//               // a.pusH(("0"))
//             }
            
//           }
//           }
//           console.log(a)
//           setCate2(a)
//         })
//         })
        fetch("https://garden-oasis-landscapping-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            // setMainCatogories(res3)
            

            let dat = []
            res3.map((res55,c)=>{
                dat.push(res55)
            })
            // console.log(SubClassCategories,asse)
            console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCatogories(data)
            
        })
        fetch("https://garden-oasis-landscapping-main-back.vercel.app/AllCategories",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res2=>res2.json())
      .then(res3=>{
        let unique_values = res3
        .map((item) => item.CategoriesName)
        .filter(
            (value, index, current_value) => current_value.indexOf(value) === index
        );
          // console.log(res3);
          // const Data1 = res3.filter((res4,i)=>{
          //     if (res4.MainCategories === props.history.location.pathname.split("/")[2])
          //     return res4
          // })
          // console.log(Data1,res3)
          setSubCategories(unique_values)
          // setSubCategoriesname(res3)
          fetch("https://garden-oasis-landscapping-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res12=>res12.json())
        .then(res13=>{
          let a = []
          console.log(res13)
          for (let i = 0; i < res13.length; i++) {
          for (let j = 0; j < res3.length; j++) {
            // console.log(res3[j].MainCategories , res13[i].MainCategories)
            if(res3[j].MainCategories === res13[i].MainCategories){
              // a.push("1")
// console.log(i,j)
              a[i] = "1"
              break
            }
            else{
              a[i] = "0"
              // a.pusH(("0"))
            }
            
          }
          }
          // console.log(a)
          setCate1(a)
        })
      })




        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

        // if ( JSON.parse(localStorage.getItem("User"))  ){
        //     setuserHeader(true)
        //     setUserDatat(JSON.parse(localStorage.getItem("User")))
        //     const cartUser1 = JSON.parse(localStorage.getItem("Cart"))
        //     if (cartUser1 ){
        // fetch("https://garden-oasis-landscapping-main-back.vercel.app/user-cart-add",{
        //                         method: "POST",
        //                         headers :  {
        //                             "Content-Type" : "application/json" , 
        //                         } ,
        //                         body : JSON.stringify({
        //                             cart : cartUser1 ,
        //                             user : JSON.parse(localStorage.getItem("User")) 
        //                         })
        //                     })
        //                     .then(res=>res.json())
        //                     .then((res1)=>{ 
        //                         console.log(res1);
        //                     })

        //     }
        // }
        var data1 =  JSON.parse(localStorage.getItem("Cart")) 
      //   fetch("https://garden-oasis-landscapping-main-back.vercel.app/FAQ1",{
      //     method: "GET",
      //      headers :  {
      //      "Content-Type" : "application/json" , 
      //  } ,
      // })
      // .then(res3=>res3.json())
      // .then(res4=>{
      //   let dat1 = []
      //   res4.map((res55,c)=>{
      //       dat1.push(0)
      //   })
      //   setfvalue(dat1)
      //        setdata(res4)
      //        data.map((res,i)=>{
      //         data1[i] = res.text
      //        })

      //     console.log(res4)
      //   })
        fetch("https://garden-oasis-landscapping-main-back.vercel.app/AllProduct",{
       method: "GET",
        headers :  {
        "Content-Type" : "application/json" , 
    }
   })
   .then(res5=>res5.json())
   .then(res6=>{
    localStorage.setItem("Prod",JSON.stringify(res6))
    //    console.log(res6);
    const pro = []
    const pro1 = []
    const pro2 = []
    const pro3 = []
    res6.map((res7,i)=>{
        //    console.log(res7.Product_Popular );
        if ( res7.Arrival){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro.push(res7)
        }
        if ( res7.Arrival1){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro1.push(res7)
        }
        if ( res7.Arrival2){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro2.push(res7)
        }
        if ( res7.Arrival3){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro3.push(res7)
        }
    })
    // setproduct(pro.reverse())
    setproduct8(pro)
    setproduct9(pro1)
    setproduct10(pro2)
    setproduct11(pro3)
    setproduct(pro.slice(0,8))
    setproduct1(pro1.slice(0,8))
    // setproduct1(pro1.slice(0,7))
    // setproduct1(pro1)
    setdis(true)
   })
    
//     fetch("/AllCategories",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res=>res.json())
//    .then(res1=>{
//     setCategories(res1)
   
//    })

    
//     fetch("/AllHomomethtic",{
//        method: "GET",
//         headers :  {
//         "Content-Type" : "application/json" , 
//     } ,
//    })
//    .then(res3=>res3.json())
//    .then(res4=>{
//     setHomo(res4)
//     // console.log(res4);
//    })

 },[])

 const savethedetailproduct = (data) =>{
    localStorage.setItem("Data" , JSON.stringify(data) )
    console.log(data);
 }
 
 
 const cate =(Categories) =>{
     var Cat1 = Categories.split(" ").join("-")
     
     localStorage.setItem("Cate" , JSON.stringify(Categories) )
     
     setTimeout(()=>{
            props.history.push(`/shop/categories/AED {Cat1}`)
        },1500)
    }
    
    
//     const addtocartproduct = (data111) =>{
//     //    localStorage.setItem("Data" , JSON.stringify(data) )
//        console.log(data111);


//        if(data111.ProductCreater !== "60a3c644e4520a12c80a6f52"){
//         console.log("yes ");
//         fetch("https://garden-oasis-landscapping-main-back.vercel.app/ChangePercentageInOrder/"+data111.ProductCreater,{
//                             method: "POST",
//                             headers :  {
//                                 "Content-Type" : "application/json" , 
//                             }
//                         })
//                         .then(res=>res.json())
//                         .then((res1)=>{ 
//                             console.log(res1);
//                             // if(!res1 === null){
// document.getElementById("myDi").style.visibility = "visible"
//     setTimeout(()=>{
//         document.getElementById("myDi").style.visibility = "hidden"

//     },1000)
//     if (data111.Product_Price_Discounted === null){
//         const data =  {...data111 ,
                    
//                     Percentage : data111.Percentage,
//                     DeliveryStatus : "Pending",
//                      Pieces :1,
//                      Total_Product_Price  : data111.Product_Price *1}
//     var data1 = JSON.parse(localStorage.getItem("Cart")) 
//     if (data1){
//         var data3 = data1.map((item) => {
//             if(item._id === data111._id){
//                 console.log("double");
// ;                   localStorage.setItem("double",JSON.stringify(true))
//                 return {...item,
//                     Pieces :1+ item.Pieces,
//                     Total_Product_Price  : (data111.Product_Price *1)+ item.Total_Product_Price}
//             }
//             else{
//                 console.log("double not match");
//                 return item
//             }

//         })
//         var data5 =  JSON.parse(localStorage.getItem("double")) 
//         // console.log(DataPart2.length, data3.length,data5);
//         var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//         if(data10.length=== data3.length && data5){
//             console.log("double remove");
//             localStorage.removeItem("double")
//             localStorage.setItem("Cart" , JSON.stringify(data3) )
         
//         }
//         else{
//             console.log("Differet");
//             var data2 = [...data1 , data]
        
//        localStorage.setItem("Cart" , JSON.stringify(data2) )
//         }
//     }
//     else{
//         console.log("1");
//         localStorage.setItem("Cart" , JSON.stringify([data]) )
      
//     }

//     }
//     else if (data111.Product_Price_Discounted){
//         const data =  {...data111 ,
                    
//                     Percentage : data111.Percentage,
//                     DeliveryStatus : "Pending",
//                      Pieces :1,
//                      Total_Product_Price  : data111.Product_Price_Discounted *1}
//     var data1 = JSON.parse(localStorage.getItem("Cart")) 
//     if (data1){
//         var data3 = data1.map((item) => {
//             if(item._id === data111._id){
//                 console.log("double");
// ;                   localStorage.setItem("double",JSON.stringify(true))
//                 return {...item,
//                     Pieces :1+ item.Pieces,
//                     Total_Product_Price  : (data111.Product_Price_Discounted *1)+ item.Total_Product_Price}
//             }
//             else{
//                 console.log("double not match");
//                 return item
//             }

//         })
//         var data5 =  JSON.parse(localStorage.getItem("double")) 
//         // console.log(DataPart2.length, data3.length,data5);
//         var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//         if(data10.length=== data3.length && data5){
//             console.log("double remove");
//             localStorage.removeItem("double")
//             localStorage.setItem("Cart" , JSON.stringify(data3) )
         
//         }
//         else{
//             console.log("Differet");
//             var data2 = [...data1 , data]
        
//        localStorage.setItem("Cart" , JSON.stringify(data2) )
//         }
//     }
//     else{
//         console.log("1");
//         localStorage.setItem("Cart" , JSON.stringify([data]) )
      
//     }

//     }
//     else if (data111.Size_Discounted[0]){
//         const data =  {...data111 ,
//             Percentage : data111.Percentage,
//             DeliveryStatus : "Pending",
//             Pieces :1,
//             Size : data111.Size[0],
//             Product_Price_Discounted : data111.Size_Discounted[0],
//             Total_Product_Price  : data111.Size_Discounted[0] *1}
//             var data1 = JSON.parse(localStorage.getItem("Cart")) 
//             if (data1){
//             var data3 = data1.map((item) => {
//                 if(item._id === data111._id &&  item.Size === data111.Size[0]){
//                     console.log("double");
//             ;                   localStorage.setItem("double",JSON.stringify(true))
//                     return {...item,
//                         Pieces :1+ item.Pieces,
//                         Total_Product_Price  : (data111.Size_Discounted[0] *1)+ item.Total_Product_Price}
//                 }
//                 else{
//                     console.log("double not match");
//                     return item
//                 }

//             })
//             var data5 =  JSON.parse(localStorage.getItem("double")) 
//             // console.log(DataPart2.length, data3.length,data5);
//             var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//             if(data10.length=== data3.length && data5){
//                 console.log("double remove");
//                 localStorage.removeItem("double")
//                 localStorage.setItem("Cart" , JSON.stringify(data3) )
            
//             }
//             else{
//                 console.log("Differet");
//                 var data2 = [...data1 , data]
            
//             localStorage.setItem("Cart" , JSON.stringify(data2) )
//             }
//             }
//             else{
//             console.log("1");
//             localStorage.setItem("Cart" , JSON.stringify([data]) )

//             }
//     }


//     else{




//                         const data =  {...data111 ,
//                             Percentage : data111.Percentage,
//                             DeliveryStatus : "Pending",
//                             Pieces :1,
//                             Size : data111.Size[0],
//                             Product_Price_Discounted : data111.Price[0],
//                             Total_Product_Price  : data111.Price[0] *1}
//             var data1 = JSON.parse(localStorage.getItem("Cart")) 
//             if (data1){
//             var data3 = data1.map((item) => {
//                 if(item._id === data111._id &&  item.Size === data111.Size[0]){
//                     console.log("double");
//             ;                   localStorage.setItem("double",JSON.stringify(true))
//                     return {...item,
//                         Pieces :1+ item.Pieces,
//                         Total_Product_Price  : (data111.Price[0] *1)+ item.Total_Product_Price}
//                 }
//                 else{
//                     console.log("double not match");
//                     return item
//                 }

//             })
//             var data5 =  JSON.parse(localStorage.getItem("double")) 
//             // console.log(DataPart2.length, data3.length,data5);
//             var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//             if(data10.length=== data3.length && data5){
//                 console.log("double remove");
//                 localStorage.removeItem("double")
//                 localStorage.setItem("Cart" , JSON.stringify(data3) )
              
//             }
//             else{
//                 console.log("Differet");
//                 var data2 = [...data1 , data111]
             
//             localStorage.setItem("Cart" , JSON.stringify(data2) )
//             }
//             }
//             else{
//             console.log("1");
//             localStorage.setItem("Cart" , JSON.stringify([data]) )
      
//             }


//     }


//   })
    

// }













// else{
  
//                 document.getElementById("myDi").style.visibility = "visible"
//                 setTimeout(()=>{
//                 document.getElementById("myDi").style.visibility = "hidden"

//                 },1000)
//                 // console.log(productDetail);
//                 if (data111.Product_Price_Discounted === null){
//                 const data =  {...data111 ,
//                     Percentage : 0,
//                     DeliveryStatus : "Pending",
//                     Pieces :1,
//                     Total_Product_Price  : data111.Product_Price *1}
//                 var data1 = JSON.parse(localStorage.getItem("Cart")) 
//                 console.log(  data1);
//                 if (data1){
//                     console.log(  data111._id);
//                 var data3 = data1.map((item) => {
//                     console.log(item._id , data111._id);
//                     if(item._id === data111._id ){
//                         console.log("double");
//                 ;                   localStorage.setItem("double",JSON.stringify(true))
//                 return {...item,
//                     Pieces :1+ item.Pieces,
//                     Total_Product_Price  : (data111.Product_Price *1)+ item.Total_Product_Price}
//                 }
//                 else{
//                 console.log("double not match");
//                 return item
//                 }

//                 })
//                 var data5 =  JSON.parse(localStorage.getItem("double")) 
//                 // console.log(DataPart2.length, data3.length,data5);
//                 var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//                 if(data10.length=== data3.length && data5){
//                 console.log("double remove");
//                 localStorage.removeItem("double")
//                 localStorage.setItem("Cart" , JSON.stringify(data3) )

//                 }
//                 else{
//                 console.log("Differet");
//                 var data2 = [...data1 , data111]

//                 localStorage.setItem("Cart" , JSON.stringify(data2) )
//                 }
//                 }
//                 else{
//                 console.log("1");
//                 localStorage.setItem("Cart" , JSON.stringify([data]) )

//                 }

//                 }
//                 else if (data111.Product_Price_Discounted){
//                 const data =  {...data111 ,
//                     Percentage : 0,
//                     DeliveryStatus : "Pending",
//                     Pieces :1,
//                     Total_Product_Price  : data111.Product_Price_Discounted *1}
//                 var data1 = JSON.parse(localStorage.getItem("Cart")) 
//                 console.log(  data1);
//                 if (data1){
//                     console.log(  data111._id);
//                 var data3 = data1.map((item) => {
//                     console.log(item._id , data111._id);
//                     if(item._id === data111._id ){
//                         console.log("double");
//                 ;                   localStorage.setItem("double",JSON.stringify(true))
//                 return {...item,
//                     Pieces :1+ item.Pieces,
//                     Total_Product_Price  : (data111.Product_Price_Discounted *1)+ item.Total_Product_Price}
//                 }
//                 else{
//                 console.log("double not match");
//                 return item
//                 }

//                 })
//                 var data5 =  JSON.parse(localStorage.getItem("double")) 
//                 // console.log(DataPart2.length, data3.length,data5);
//                 var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//                 if(data10.length=== data3.length && data5){
//                 console.log("double remove");
//                 localStorage.removeItem("double")
//                 localStorage.setItem("Cart" , JSON.stringify(data3) )

//                 }
//                 else{
//                 console.log("Differet");
//                 var data2 = [...data1 , data111]

//                 localStorage.setItem("Cart" , JSON.stringify(data2) )
//                 }
//                 }
//                 else{
//                 console.log("1");
//                 localStorage.setItem("Cart" , JSON.stringify([data]) )

//                 }

//                 }
//                 else if (data111.Size_Discounted[0]){
//                     const data =  {...data111 ,
//                         Percentage : 0,
//                         DeliveryStatus : "Pending",
//                         Pieces :1,
//                         Size : data111.Size[0],
//                         Product_Price_Discounted : data111.Size_Discounted[0],
//                         Total_Product_Price  : data111.Size_Discounted[0] *1}
//             var data1 = JSON.parse(localStorage.getItem("Cart")) 
//             if (data1){
//             var data3 = data1.map((item) => {
//                 if(item._id === data111._id &&  item.Size === data111.Size[0]){
//                     console.log("double");
//             ;                   localStorage.setItem("double",JSON.stringify(true))
//                 return {...item,
//                     Pieces :1+ item.Pieces,
//                     Total_Product_Price  : (data111.Size_Discounted[0] *1)+ item.Total_Product_Price}
//             }
//             else{
//                 console.log("double not match");
//                 return item
//             }

//             })
//             var data5 =  JSON.parse(localStorage.getItem("double")) 
//             // console.log(DataPart2.length, data3.length,data5);
//             var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//             if(data10.length=== data3.length && data5){
//             console.log("double remove");
//             localStorage.removeItem("double")
//             localStorage.setItem("Cart" , JSON.stringify(data3) )

//             }
//             else{
//             console.log("Differet");
//             var data2 = [...data1 , data111]

//             localStorage.setItem("Cart" , JSON.stringify(data2) )
//             }
//             }
//             else{
//             console.log("1");
//             localStorage.setItem("Cart" , JSON.stringify([data]) )

//             }

//                 }
//                 else{




//                         const data =  {...data111 ,
//                             Percentage : 0,
//                             DeliveryStatus : "Pending",
//                             Pieces :1,
//                             Size : data111.Size[0],
//                             Product_Price_Discounted : data111.Price[0],
//                             Total_Product_Price  : data111.Price[0] *1}
//                 var data1 = JSON.parse(localStorage.getItem("Cart")) 
//                 if (data1){
//                 var data3 = data1.map((item) => {
//                     if(item._id === data111._id &&  item.Size === data111.Size[0]){
//                         console.log("double");
//                 ;                   localStorage.setItem("double",JSON.stringify(true))
//                     return {...item,
//                         Pieces :1+ item.Pieces,
//                         Total_Product_Price  : (data111.Price[0] *1)+ item.Total_Product_Price}
//                 }
//                 else{
//                     console.log("double not match");
//                     return item
//                 }

//                 })
//                 var data5 =  JSON.parse(localStorage.getItem("double")) 
//                 // console.log(DataPart2.length, data3.length,data5);
//                 var data10 =  JSON.parse(localStorage.getItem("Cart")) 

//                 if(data10.length=== data3.length && data5){
//                 console.log("double remove");
//                 localStorage.removeItem("double")
//                 localStorage.setItem("Cart" , JSON.stringify(data3) )

//                 }
//                 else{
//                 console.log("Differet");
//                 var data2 = [...data1 , data111]

//                 localStorage.setItem("Cart" , JSON.stringify(data2) )
//                 }
//                 }
//                 else{
//                 console.log("1");
//                 localStorage.setItem("Cart" , JSON.stringify([data]) )

//                 }


//                 }


//                     }






































//     }

    
const setChangerItemSize = (e)=>{

  if (fg) setfg(false)
  else setfg(true)

  for (let i = 0; i < 45; i++) {
    if(i === e){
      if(fvalue[e] === 0){
          fvalue[e] = 1
          // let f = document.getElementById("changeIcon"+i)
           document.getElementById("changeIcon1"+i).style.transform = "rotate(180deg)"
          
      }
      else {
          fvalue[e] = 0
          // let v = document.getElementById("changeIcon"+i)
           document.getElementById("changeIcon1"+i).style.transform = "rotate(0deg)"
          // v.innerHTML= ""
       }
    }
 
  }

setfvalue(fvalue)
  if (fg) setfg(false)
  else setfg(true)
  
}
    const carrouselInit =() => {

      var carrousel = document.getElementById("carrouselimg1");
      carrousel.style.opacity = "1";
      setInterval(carrouselAutoChange, 3500);
    
    }
    
    const carrouselAutoChange=()  =>{
      carrouselRight();
    }
    
    
    
    const carrouselChange=(i) => {
    
      var carrousel = document.getElementById("carrouselimg" + i);
      carrousel.style.opacity = "1";
    
    }
    
    
    const carrouselLeft=()  =>{
    
      let nbCarrousel = 4;
      let num = 0;
    
      for (let i = 0; i < nbCarrousel; i++) {
          num = i + 1;
          var carrousel = document.getElementById("carrouselimg" + num);
          if (carrousel.style.opacity == "1") {
              carrousel.style.opacity = "0";
              if (i == 0) {
                  return carrouselChange(4);
              }
              return carrouselChange(num - 1);
          }
      }
    }
    
    const carrouselRight=() =>{
    
      let nbCarrousel = 4;
      let num = 0;
    
      for (let i = 0; i < nbCarrousel; i++) {
          num = i + 1;
          var carrousel = document.getElementById("carrouselimg" + num);
          if (carrousel.style.opacity == "1") {
              carrousel.style.opacity = "0";
              if (i == 3) {
                  return carrouselChange(1);
              }
              return carrouselChange(num + 1);
          }
      }
    }
    
    
    // const addtocartproduct = (productDetail) =>{
    //   swal("Update!", "Your Item Add in Cart!", "success");
    
    //   // if(productDetail){
    //       // console.log(typeof(productDetail[0].ProductCreater), typeof("60a3c644e4520a12c80a6f52"));
          
    
    
        
    //                   if (productDetail[0].Product_Price_Discounted === null){
    //                   const data =  {...productDetail[0] ,
    //                       Percentage : 0,
    //                       DeliveryStatus : "Pending",
    //                       Pieces :1,
    //                       Total_Product_Price  : productDetail[0].Product_Price *1}
    //                   var data1 = JSON.parse(localStorage.getItem("Cart")) 
    //                   if (data1){
    //                   var data3 = data1.map((item) => {
    //                       if(item._id === data._id ){
    //                           console.log("double");
    //                   ;                   localStorage.setItem("double",JSON.stringify(true))
    //                   return {...item,
    //                       Pieces :1+ item.Pieces,
    //                       Total_Product_Price  : (productDetail[0].Product_Price *1)+ item.Total_Product_Price}
    //                   }
    //                   else{
    //                   console.log("double not match");
    //                   return item
    //                   }
    
    //                   })
    //                   var data5 =  JSON.parse(localStorage.getItem("double")) 
    //                   console.log(DataPart2.length, data3.length,data5);
    //                   var data10 =  JSON.parse(localStorage.getItem("Cart")) 
    
    //                   if(data10.length=== data3.length && data5){
    //                   console.log("double remove");
    //                   localStorage.removeItem("double")
    //                   localStorage.setItem("Cart" , JSON.stringify(data3) )
    
    //                   }
    //                   else{
    //                   console.log("Differet");
    //                   var data2 = [...data1 , data]
    
    //                   localStorage.setItem("Cart" , JSON.stringify(data2) )
    //                   }
    //                   }
    //                   else{
    //                   console.log("1");
    //                   localStorage.setItem("Cart" , JSON.stringify([data]) )
    
    //                   }
    
    //                   }
    //                   else if (productDetail[0].Product_Price_Discounted){
    //                   const data =  {...productDetail[0] ,
    //                       Percentage : 0,
    //                       DeliveryStatus : "Pending",
    //                       Pieces :1,
    //                       Total_Product_Price  : productDetail[0].Product_Price_Discounted *1}
    //                   var data1 = JSON.parse(localStorage.getItem("Cart")) 
    //                   if (data1){
    //                   var data3 = data1.map((item) => {
    //                       if(item._id === data._id ){
    //                           console.log("double");
    //                   ;                   localStorage.setItem("double",JSON.stringify(true))
    //                   return {...item,
    //                       Pieces :1+ item.Pieces,
    //                       Total_Product_Price  : (productDetail[0].Product_Price_Discounted *1)+ item.Total_Product_Price}
    //                   }
    //                   else{
    //                   console.log("double not match");
    //                   return item
    //                   }
    
    //                   })
    //                   var data5 =  JSON.parse(localStorage.getItem("double")) 
    //                   console.log(DataPart2.length, data3.length,data5);
    //                   var data10 =  JSON.parse(localStorage.getItem("Cart")) 
    
    //                   if(data10.length=== data3.length && data5){
    //                   console.log("double remove");
    //                   localStorage.removeItem("double")
    //                   localStorage.setItem("Cart" , JSON.stringify(data3) )
    
    //                   }
    //                   else{
    //                   console.log("Differet");
    //                   var data2 = [...data1 , data]
    
    //                   localStorage.setItem("Cart" , JSON.stringify(data2) )
    //                   }
    //                   }
    //                   else{
    //                   console.log("1");
    //                   localStorage.setItem("Cart" , JSON.stringify([data]) )
    
    //                   }
    
    //                   }
    
    
                          
    
    // }
    
    


const addtocartproduct = (productDetail) =>{
  swal("Update!", "Your Item Add in Cart!", "success");

      
  // document.getElementById("myDi").style.visibility = "visible"
  // setTimeout(()=>{
  // document.getElementById("myDi").style.visibility = "hidden"

  // },1000)
  console.log(productDetail)
  if (productDetail[0].Product_Price_Discounted === null){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Product_Price_Discounted){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Size_Discounted[0]){
      
          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Size_Discounted[0],
              Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else{




          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Price[0],
              Total_Product_Price  : productDetail[0].Price[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }


  }


      

}

const addtocartproduct8 = (productDetail) =>{
  swal("Update!", "Item Save!", "success");

      
  // document.getElementById("myDi").style.visibility = "visible"
  // setTimeout(()=>{
  // document.getElementById("myDi").style.visibility = "hidden"

  // },1000)
  console.log(productDetail)
  if (productDetail[0].Product_Price_Discounted === null){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Product_Price_Discounted){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Size_Discounted[0]){
      
          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Size_Discounted[0],
              Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }

  }
  else{




          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Price[0],
              Total_Product_Price  : productDetail[0].Price[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Fav")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Fav")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Fav" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Fav" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Fav" , JSON.stringify([data]) )

  }


  }


      

}

const setIsShown1 = (data) =>{
  console.log(SubClassCategories2,data)
  const NonActive = SubClassCategories2.filter((res9,i)=>{
    // console.log(res9.status , res9.Product_Catagories , Categoriesname.categoriesName  , res9.Product_Sub_Catagories , Categoriesname.subCate );
    if( res9.MainCategories === data.MainCategories   ){
        return res9 
    }
})
console.log(NonActive)
setSubClassCategories1(NonActive)
// console.log(NonActive)

  document.getElementById("drf").style.display = "block"
  console.log("1")
}

const setIsShown3 = () =>{
  document.getElementById("drf").style.display = "block"
  console.log("1")
}

const setIsShown2 = () =>{
  document.getElementById("drf").style.display = "none"

}
    
        return (
            
          <div>
          <div className="page-wrapper">
            
            {/* End .header */}
            <main className="main">
            {/* <main className="main" style={{backgroundColor: '#fafafa'}}> */}
              <div className="intro-section change-height-slider" >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-5cols d-none d-lg-block" style={{maxHeight: "500px",
    overflowY: "scroll"}}>
                      <nav className="side-nav">
                        <div className="sidenav-title letter-spacing-normal font-size-normal d-flex justify-content-xl-between align-items-center bg-primary justify-content-center text-truncate">Browse Categories
                          <i className="icon-bars float-right h5 text-white m-0 d-none d-xl-block" />
                        </div>
                        {/* End .sidenav-title   font-size-normal */}
                        <ul className="menu-vertical sf-arrows sf-js-enabled" style={{touchAction: 'pan-y'}}>
                          
                       
                      
                       
                          {MainCatogories.map((res,i)=>{
                   if(res.MainCategories  === "(AMC) Annual Maintenance Contract"){
                       return (
                      <>
                          <li  onMouseEnter={() => setIsShown1(res)}
        onMouseLeave={() => setIsShown2(false)}
        >
                          <Link to={"/categories-main/"+"Annual Maintenance Contract" } className="text-dark">
                              <i className="icon-blender" /> Annual Maintenance Contract
</Link>
                          </li>
                       </>
                    
                    )  
                  }
                   else if(res.MainCategories  === "Landscaping"){
                       return (
                      <>
                          <li  onMouseEnter={() => setIsShown1(res)}
        onMouseLeave={() => setIsShown2(false)}
        >
                          <Link to={"/categories-main/"+res.MainCategories } className="text-dark">
                              <i className="icon-blender" /> {res.MainCategories}</Link>
                          </li>
                       </>
                    
                    )  
                  }
                  else{
                    return (
                    <>
                        <li  onMouseEnter={() => setIsShown1(res)}
      onMouseLeave={() => setIsShown2(false)}
      >
                       <Link to={"/categories/"+res.MainCategories+ "/all-products"} className="text-dark">
                            <i className="icon-blender" /> {res.MainCategories}</Link>
                        </li>
                     </>
                  
                  )  

                   }
                          
                            
                   
                  
                  })
                

                              }


                       
                        </ul>
                        {/* End .menu-vertical */}
                      </nav>
                      {/* End .dropdown-menu */}
                    </div>
                    <div  onMouseEnter={() => setIsShown3()}  onMouseLeave={() => setIsShown2(false)} id="drf" style={{position: "absolute",
    left: "17%",
    zIndex: "9999",
    background: "white",
    height: "361px",
    overflowY: "scroll",
    display:"none",
    top: "1.5%"}}> 
                       <ul className="menu-vertical sf-arrows sf-js-enabled" style={{touchAction: 'pan-y'}}>
                          
                       

                       
                          {SubClassCategories1.map((res,i)=>{
                   
                         return (
                      <>
                          <li>
                            <Link to={"/categories/"+res.MainCategories+"/"+res.CategoriesName+"/all-products" } className="text-dark" style={{padding  :"10px 30px"}}>
                             {res.CategoriesName}</Link>
                          </li>
                       </>
                    
                    )      
                            
                   
                  
                  })
                

                              }


                       
                        </ul>
                        </div>
                    <div className="col-xl-9 col-md-12 col-12 mb-md-0">
                      <div className="intro-slider-container">
                        {/* <div className="intro-slider owl-carousel owl-theme owl-nav-inside row cols-1" data-toggle="owl" data-owl-options="{
                                          &quot;nav&quot;: false,
                                          &quot;dots&quot;: true,
                                          &quot;autoplay&quot;: false,
                                          &quot;autoplayTimeout&quot;: 10000,
                                          &quot;animateOut&quot;: &quot;fadeOut&quot;
                                      }"> */}










{/* <Slider {...settings}>   */}

<Carousel autoPlay={true} interval={4000} showThumbs={false} showArrows={true} showStatus={false} swipeable={true} swipeAble={true} infiniteLoop={true} style={{height: "300px"}}>


<div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}}  onClick={()=>props.history.push("categories-main/Annual Maintenance Contract")}>
    <img src={slid1} alt="" className='change-slid' style={{height: "485px"}}/>
  </div>
  <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} onClick={()=>props.history.push("categories-main/Landscaping")}   >
    <img src={slid2} alt="" className='change-slid' style={{height: "485px"}}   />
  </div>
  <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} onClick={()=>props.history.push("/categories/Indoor%20Flowering%20Plants/all-products")}>
    <img src={slid3} alt="" className='change-slid' style={{height: "485px"}}/>
  </div>
  <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}}  onClick={()=>props.history.push("/categories/Outdoor%20Plants/all-products")}>
    <img src={slid4} alt="" className='change-slid' style={{height: "485px"}}/>
  </div>
  <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}}  onClick={()=>props.history.push("/categories/Garden%20Accessories/all-products")}>
    <img src={slid5} alt="" className='change-slid' style={{height: "485px"}}/>
  </div>


  {/* {SliderPhoto[0] &&SliderPhoto[0].photo1 !== null ? 
    <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} >
    <img src={SliderPhoto[0].photo1} alt="" className='change-slid' style={{height: "485px"}}/>
  </div>
    :""
  }
  {SliderPhoto[0] &&SliderPhoto[0].photo2 !== null ? 
    <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} >
    <img src={SliderPhoto[0].photo2} alt="" className='change-slid' style={{height: "485px"}}/>
  </div>
        :""
      }
  {SliderPhoto[0] &&SliderPhoto[0].photo3 !== null? 
    <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} >
    <img src={SliderPhoto[0].photo3} alt="" className='change-slid' style={{height: "485px"}}/>
  </div>
    :""
  }
  {SliderPhoto[0] &&SliderPhoto[0].photo4!== null ? 
    <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} >
    <img src={SliderPhoto[0].photo4} alt="" className='change-slid' style={{height: "485px"}}/>
  </div>
  :""
} */}
  </Carousel>


                          {/* </Slider> */}

                          {/* End .intro-slide */}
                        </div>
                        {/* End .intro-slider owl-carousel owl-simple */}
                        {/* 
                                      <span class="slider-loader"></span>
                                  End .slider-loader */}
                      {/* </div> */}
                    </div>
                    {/* <div className="col-lg-5cols col-md-3 col-12 mb-md-0 mb-2">
                      <div className="banner banner-overlay bg-image h-100 mb-0" style={{backgroundColor: '#f1f1f1', backgroundImage: 'url(assets/images/demos/demo-26/banners/banner-1.jpg)'}}>
                        <div className="banner-content position-relative pt-0 pb-md-7 d-flex flex-column">
                          <div className="title text-center text-uppercase text-dark font-weight-bold mb-0">
                            Phantom 3
                            <br />Professional
                          </div>
                          <div className="price text-center">
                            <sup className="text-dark font-weight-normal">from
                              <span className="text-primary font-weight-normal">AED </span>
                            </sup>
                            <span className="text-primary font-weight-bold">
                              599.
                            </span>
                            <sup className="text-primary font-weight-bold" style={{fontSize: '55%', top: '-.6em'}}>99</sup>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>




             

              {/* <div className="container electronics fashion">
                <div className="bg-lighter trending-products">
                  <div className="heading heading-flex">
                    <div className="heading-left">
                      <h2 className="title font-weight-bold mb-1">Home  &amp; Appliances</h2>
                    </div>
                    <div className="heading-right">
                      <ul className="nav nav-pills justify-content-center mr-n3" role="tablist">
                        {SubCategories.map((res,i)=>{
                          let ab = res.replace(" " , "-")

                          if(i === 0){
                            return(
                              <li className="nav-item">
                              <span className="nav-link font-size-normal font-weight-normal text-uppercase dfkljsdklj active" id={`fashion-${ab}-link`} data-toggle="tab" href={`#fashion-${ab}-tab`} role="tab" aria-controls={`fashion-${ab}-tab`} aria-selected="true" style={{color : "black"}} >{res}</span>
                            </li>
                            )
                          }
                          else{
                            return(
                              <li className="nav-item">
                              <span className="nav-link font-size-normal font-weight-normal text-uppercase dfkljsdklj" id={`fashion-${ab}-link`} data-toggle="tab" href={`#fashion-${ab}-tab`} role="tab" aria-controls={`fashion-${ab}-tab`} aria-selected="true" style={{color : "black"}} >{res}</span>
                            </li>
                            )
                          }
                        })

                        }
                        
                      </ul>
                    </div>
                  </div>
                  <div className="tab-content tab-content-carousel position-relative">
                  {SubCategories.map((res,i)=>{
                    let ab = res.replace(" " , "-")
                          if(i === 0){
                            return(
                              <div className="tab-pane p-0 fade show active" id={`fashion-${ab}-tab`} role="tabpanel" aria-labelledby={`fashion-${ab}-link`}>
                                <div>
                                    <Carousel3 handleClick={res} />
                                </div>
                              </div>
                            )
                          }
                          else{
                            return(
                              <div className="tab-pane p-0 fade" id={`fashion-${ab}-tab`} role="tabpanel" aria-labelledby={`fashion-${ab}-link`}>
                                <div>
                                    <Carousel3 handleClick={res} />
                                </div>
                            </div>
                            )
                          }
                        })

                        }
                    
                    </div>
                    
                </div>
              </div>




           */}













          



















































             <br />

              <div className="container banner-group-1">
                <div className="categories">
                  <center>

                  <h3 className="title text-center font-weight-bold mt-1" style={{marginBottom : "0px",borderBottom :"3px solid rgb(40, 108, 83)",width: "fit-content",textAlign:"center"}}>Top Categories this Week</h3>
                  </center>
                  <div 
                
                              >
     
                    <Cate />
                  
                    
                  </div>
                </div>
              
              <div className="container deal-section">
                <br />
                <center>

<h3 className="title text-center font-weight-bold mt-1 mb-2" style={{marginBottom : "0px",borderBottom :"3px solid rgb(40, 108, 83)",width: "fit-content",textAlign:"center"}}>Top Selling</h3>
</center>
                
                <div>
            

<Carousel1/>
      
                </div>  
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="">
                      <div className="bg-image d-flex justify-content-center kjkjkjkj" style={{backgroundImage: 'url('+slid6+')',backgroundRepeat : "no-repeat" , backgroundSize : "contain",height:  "410px",backgroundColor : "transparent"}}>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
                <div className="container icon-boxes-section">
                <div className="icon-boxes-container py-4 bg-lighter mb-2 mt-2">

                  <div>
                 
                 < Ico/>
                

                  </div>

                </div>



           <div className="container deal-section">
                <div style={{display : "flex",marginBottom: "10px",
    justifyContent: "space-between"}}>

              <h1 className="title font-weight-bold" style={{  height: "39px",padding  : "0px",margin : "0px" , borderBottom : "2px solid #286C53"}}> Indoor Flowering  
</h1>
               <Link to={"/categories/Indoor Flowering Plants/all-products" }><span  className="heading-right" style={{color : "white" , padding : "7px 8px" ,  background  :"#286C53", fontWeight  : "600"}} >VIEW ALL</span></Link>
                        
                </div>
                <div>
             



<Carousel4/>

                       
                </div>  
              </div>
              <br />
              <div className="container deal-section">
                <div style={{display : "flex",marginBottom: "10px",
    justifyContent: "space-between"}}>
                <h1 className="title font-weight-bold" style={{height: "39px",padding  : "0px",margin : "0px" , borderBottom : "2px solid #286C53"}}> Outdoor Plants


</h1>
<Link to={"/categories/Outdoor Plants/all-products" }><span  className="heading-right" style={{color : "white" , padding : "7px 8px" ,  background  :"#286C53", fontWeight  : "600"}} >VIEW ALL</span></Link>
                        
                        </div>
                        <div>
                     


<Carousel5/>

                       
                </div>  
              </div>
              <br />
              <div className="container deal-section">
                <div style={{display : "flex",marginBottom: "10px",
    justifyContent: "space-between"}}>
                <h1 className="title font-weight-bold" style={{height: "39px",padding  : "0px",margin : "0px" , borderBottom : "2px solid #286C53"}}> Garden Accessories 

</h1>
<Link to={"/categories/Garden Accessories/all-products" }><span  className="heading-right" style={{color : "white" , padding : "7px 8px" ,  background  :"#286C53", fontWeight  : "600"}} >VIEW ALL</span></Link>
                        
                        </div>
                        <div>
                     



<Carousel6/>

                       
                </div>  
              </div>
      <br />
              <div className="container deal-section">
                <div style={{display : "flex",marginBottom: "10px",
    justifyContent: "space-between"}}>
                <h1 className="title font-weight-bold" style={{height: "39px",padding  : "0px",margin : "0px" , borderBottom : "2px solid #286C53"}}> Pots and Planters


</h1>
<Link to={"/categories/Pots and Planters/all-productsAUTY" }><span  className="heading-right" style={{color : "white" , padding : "7px 8px" ,  background  :"#286C53", fontWeight  : "600"}} >VIEW ALL</span></Link>
                        
                        </div>
                        <div>
                     



<Carousel7/>

                       
                </div>  
              </div>
          







                <hr className="mt-2 mb-0" />
              </div>

                
              </div>
              <br />
              
              {/* End .container-fluid */}
             
              
              
              {/* <div className="container banner-group-2">
                <div className="row row-sm">
                  <div className="col-md-6">
                    <div className="banner-overlay" style={{backgroundImage: 'url('+ch344+')',backgroundSize : "cover",backgroundColor : "none" ,backgroundRepeat :"no-repeat",marginBottom : "20px"}} >
                      <div className="banner-content banner-content-left position-relative">
                        <h4 className="banner-subtitle letter-spacing-normal font-size-normal font-weight-light mb-1"  style={{color : "black"}}>
                          <Link to="/product/recommended-product">Flash Sale is Coming</Link>
                        </h4>
                        <h3 className="banner-title font-weight-bold mb-0" style={{color : "black"}}>
                          <Link to="/product/recommended-product" className="fefee">Home Appliances
                            </Link>
                        </h3>
                        <h4 className="banner-text text-secondary font-weight-normal">upto 15% off</h4>
                        <Link to="/product/recommended-product" className="btn-product font-size-normal text-uppercase text-decoration-none btn-rounded" style={{background : "rgb(235, 61, 50)",border: "none",color : "white"}}>Shop Now&nbsp;&nbsp;
                          <i className="icon-long-arrow-right d-inline-block" />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="banner-overlay" style={{backgroundImage: 'url('+ch3444+')',backgroundSize : "cover",backgroundColor : "none" ,backgroundRepeat :"no-repeat",marginBottom : "20px"}}>
                      <div className="banner-content banner-content-left position-relative">
                        <h4 className="banner-subtitle letter-spacing-normal font-size-normal font-weight-light mb-1"   style={{color : "black"}}>
                          <Link to="/main-categories/Kitchen%20Appliances">Mega Sale is Coming</Link>
                        </h4>
                        <h3 className="banner-title font-weight-bold mb-0" style={{color : "black"}}>
                          <Link to="/main-categories/Kitchen%20Appliances" className="fefee">kitchen Appliances
                            </Link>
                        </h3>
                        <h4 className="banner-text text-secondary font-weight-normal">upto 18% off</h4>
                        <Link to="/main-categories/Kitchen%20Appliances" className="btn-product font-size-normal text-uppercase text-decoration-none btn-rounded" style={{background : "rgb(235, 61, 50)",border: "none",color : "white"}}>Shop Now&nbsp;&nbsp;
                          <i className="icon-long-arrow-right d-inline-block" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}


             

              <div className="container deal-section">
            
<center>

<h3 className="title text-center font-weight-bold mt-1 mb-2" style={{marginBottom : "0px",borderBottom :"3px solid rgb(40, 108, 83)",width: "fit-content",textAlign:"center"}}>Recommended Products</h3>
</center>
                <div
              
                      >
                      


                    <Carousel2 />



                </div>  
              </div>


              <div className="container product-group mb-lg-7 mb-4">
                <div className="row justify-content-center">
                  <div className="arrivals col-lg-4 col-md-6 mb-2 mb-xl-0">
                    <div className="heading heading-flex align-items-center">
                      <div className="heading-left">
                        <h2 className="title mb-0 font-weight-bold">FEATURED
 
</h2>
                        {/* End .title */}
                      </div>
                      {/* End .heading-left */}
                      <div className="heading-right mt-0">
                        <Link to="/product/new-arrival" className="title-link font-size-normal text-uppercase font-weight-normal shadow-none">View More
                          <i className="icon-long-arrow-right" />
                        </Link>
                      </div>
                      {/* End .heading-right */}
                    </div>
                    <div className="products d-flex flex-column justify-content-between bg-white mt-2 mt-xl-0">
                    {
                    product10.map((res,i)=>{
                      if(i <= 3){

                      
            if(res.Course_Sub_Catagories !== ""){
                return(

                      
                      
                      
                      <div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                          <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                          </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">AED {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">AED {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       AED {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>


)
}
if(res.Product_Sub_Catagories !== ""){
  console.log("323")
  return(

    <div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                          <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                         </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">AED {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">AED {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       AED {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>
)
}
else{
  return(

<div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                            <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                            </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">AED {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">AED {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       AED {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>

)
}
}
  })
    
  }



                      {/* End .product */}
                    </div>
                  </div>
                  <div className="recommend col-lg-4 mb-2 mb-xl-0 order-lg-0 order-md-first">
                    <div className="heading heading-flex align-items-center">
                      <div className="heading-left">
                        <h2 className="title mb-0 font-weight-bold">RECENT
</h2>
                        {/* End .title */}
                      </div>
                      {/* End .heading-left */}
                      <div className="heading-right mt-0">
                      <Link to="/product/recommended-product" className="title-link font-size-normal text-uppercase font-weight-normal shadow-none">View More
                          <i className="icon-long-arrow-right" />
                        </Link>
                      </div>
                      {/* End .heading-right */}
                    </div>
                    <div className="products d-flex flex-column justify-content-between bg-white mt-2 mt-xl-0">
                    {
                    product9.map((res,i)=>{
                      if(i <= 3){

                      
            if(res.Course_Sub_Catagories !== ""){
                return(

                      
                      
                      
                      <div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                          <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                          </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">AED {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">AED {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       AED {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>


)
}
if(res.Product_Sub_Catagories !== ""){
  console.log("323")
  return(

    <div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                          <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                         </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">AED {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">AED {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       AED {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>
)
}
else{
  return(

<div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                            <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                            </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">AED {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">AED {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       AED {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>

)
}
}
  })
    
  }
                    </div>
                  </div>
                  <div className="expert col-lg-4 col-md-6 mb-2 mb-xl-0">
                    <div className="heading heading-flex align-items-center">
                      <div className="heading-left">
                        <h2 className="title mb-0 font-weight-bold">TOP RATED
</h2>
                        {/* End .title */}
                      </div>
                      {/* End .heading-left */}
                      <div className="heading-right mt-0">
                        <Link to="/product/expert-pick" className="title-link font-size-normal text-uppercase font-weight-normal shadow-none">View More
                          <i className="icon-long-arrow-right" />
                        </Link>
                      </div>
                      {/* End .heading-right */}
                    </div>
                    <div className="products d-flex flex-column justify-content-between bg-white mt-2 mt-xl-0">
                    {
                    product11.map((res,i)=>{
                      if(i <= 3){

                      
            if(res.Course_Sub_Catagories !== ""){
                return(

                      
                      
                      
                      <div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                          <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/"+res.Course_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                          </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">AED {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">AED {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       AED {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>


)
}
if(res.Product_Sub_Catagories !== ""){
  console.log("323")
  return(

    <div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                          <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>

                            <Link  to={"/categories/"+res.Product_Catagories+"/"+res.Product_Sub_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                         </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">AED {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">AED {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       AED {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>
)
}
else{
  return(

<div className="product d-flex flex-row overflow-hidden mb-0 p-0 shadow-none">
                        <figure className="mb-0 product-media bg-white d-flex justify-content-center align-items-center">
                          <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>
                            <img src={res.Product_Image_Upload} alt="Product image"  width={"130px"} height={"100px"} />
                          </Link>
                        </figure>
                        {/* End .product-media bg-white d-flex justify-content-center align-items-center */}
                        <div className="product-body">
                          <div className="product-cat text-left">
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Catagories}</Link>
                          </div>
                          {/* End .product-cat  */}
                          <h3 className="product-title letter-spacing-normal font-size-normal text-left mb-0">
                            <div style={{maxWidth:"100%",maxHeight : "33px",overflow : "hidden"}}>
                            <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>{res.Product_Name}</Link>
                            </div>
                          </h3>
                          {/* End .product-title letter-spacing-normal font-size-normal */}
                          {res.Product_Price_Discounted ? 
                          <div className="product-price mb-1 text-dark">
                            <div className="new-price">AED {res.Product_Price_Discounted}</div>
                            <div className="old-price font-size-normal">AED {(res.Product_Price).toFixed(0)}</div>
                          </div>
                    
                      :
                      <div className="product-price mb-1 text-dark">
                       AED {(res.Product_Price).toFixed(0)}
                            </div>
            }
                          {/* End .product-price */}
                          <div className="ratings-container mb-0">
                            <div className="ratings font-size-normal">
                              <div className="ratings-val font-size-normal" style={{width: '80%'}} />
                              {/* End .ratings-val */}
                            </div>
                            {/* End .ratings */}
                            <span className="ratings-text ml-2">( 3 Reviews )</span>
                          </div>
                          {/* End .rating-container */}
                        </div>
                        {/* End .product-body */}
                      </div>

)
}
}
  })
    
  }
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="bg-white brand-section pt-5 pb-4">
                <div className="container">
                  <div className="owl-carousel owl-simple brands-carousel row cols-2 cols-xs-3 cols-sm-4 cols-lg-5 cols-xxl-6" data-toggle="owl" data-owl-options="{
                              &quot;nav&quot;: false, 
                              &quot;dots&quot;: false,
                              &quot;margin&quot;:  0,
                              &quot;loop&quot;: false,
                              &quot;responsive&quot;: {
                                  &quot;0&quot;: {
                                      &quot;items&quot;:2
                                  },
                                  &quot;480&quot;: {
                                      &quot;items&quot;:3
                                  },
                                  &quot;576&quot;: {
                                      &quot;items&quot;:4
                                  },
                                  &quot;992&quot;: {
                                      &quot;items&quot;:5
                                  },
                                  &quot;1600&quot;: {
                                      &quot;items&quot;:6
                                  }
                              }
                          }">
                    <span href="#" className="brand">
                      <img src="assets/images/brands/1.png" alt="Brand Name" width={85} height={35} />
                    </span>
                    <span href="#" className="brand">
                      <img src="assets/images/brands/2.png" alt="Brand Name" width={85} height={35} />
                    </span>
                    <span href="#" className="brand">
                      <img src="assets/images/brands/3.png" alt="Brand Name" width={85} height={35} />
                    </span>
                    <span href="#" className="brand">
                      <img src="assets/images/brands/4.png" alt="Brand Name" width={85} height={35} />
                    </span>
                    <span href="#" className="brand">
                      <img src="assets/images/brands/5.png" alt="Brand Name" width={85} height={35} />
                    </span>
                    <span href="#" className="brand">
                      <img src="assets/images/brands/6.png" alt="Brand Name" width={85} height={35} />
                    </span>
                    <span href="#" className="brand">
                      <img src="assets/images/brands/7.png" alt="Brand Name" width={85} height={35} />
                    </span>
                  </div>
                  <hr className="mt-5 mb-0" />
                </div>
              </div> */}




<div className='container' style={{margin : "30px 10px",width:"96%"}}>
            <div className='row'  >
            <div className="col-lg-8 col-sm-12" style={{margin  :"auto"}}>


            {/* <h4  style={{marginBottom : "1px"}}>Home Appliances</h4> */}
            {/* <h3>About Garden Oasis Landscaping: Creating Your Green Sanctuary in Dubai</h3> */}
            <h1 style={{fontSize:"3rem"}}>Your Premier Destination for Indoor & Outdoor plants & Landscaping Solutions in Dubai</h1>
            <p>At Garden Oasis Landscaping, we're dedicated to bringing the beauty of nature into your home space in Dubai. With a diverse selection of plants and garden accessories, we aim to create your own oasis of tranquility and greenery. With over 25 years of experience in the industry, our passionate team is committed to helping you transform your space into a vibrant and thriving garden paradise. Whether you're a seasoned gardener or just starting out, we're here to provide expert guidance and top-quality products to enhance your gardening experience. Explore our range and let your imagination bloom with Garden Oasis Landscaping.




</p>
<br />
  <h5>Our Mission:</h5>
  <p>At Garden Oasis Landscaping, we are on a mission to bring nature's beauty into every home in Dubai. With our diverse selection of plants and garden accessories, we aim to inspire and empower our customers to create their own green sanctuaries.</p>

<br />
  <h5>Expertise and Experience:</h5>
  <p>With over 25 years of experience in the industry, Garden Oasis Landscaping boasts a team of passionate experts dedicated to providing the highest quality plants and accessories. Our team's wealth of knowledge ensures that you receive expert guidance and support every step of the way.</p>

<br />
  <h5>Product Categories:</h5>
  <ol>
    <li><strong>Indoor Plants:</strong> Bring the beauty of nature indoors with our selection of lush greenery and vibrant blooms.</li>
    <li><strong>Outdoor Plants:</strong> Transform your outdoor space into a thriving oasis with our diverse range of outdoor plants suitable for Dubai's climate.</li>
    <li><strong>Garden Accessories:</strong> Enhance your gardening experience with our collection of high-quality accessories, including pots, planters, tools, and more.</li>
    <li><strong>Seeds and Fertilizers:</strong> Start your gardening journey from scratch with our premium seeds and fertilizers, carefully selected to ensure optimal growth and health of your plants.</li>
  </ol>

<br />
  <h5>Transform Your Space:</h5>
  <p>Whether you're a seasoned gardener or a novice enthusiast, Garden Oasis Landscaping is here to help you transform your space into a vibrant and thriving garden paradise. Explore our range today and let your imagination bloom with Garden Oasis Landscaping.</p>
  <br />
  <h5>Our Services</h5>
  <p>
    <Link style={{padding: "10px"}} to="/villa-maintenance-contract-dubai-villa-amc-dubai">Villa Maintenance Contract Dubai Villa AMC Dubai</Link>
    <Link style={{padding: "10px"}} to="/best-annual-maintenance-contract-packages">Best Annual Maintenance Contract Packages</Link>
    <Link style={{padding: "10px"}} to="/annual-maintenance-contract-in-dubai">Annual Maintenance Contract in Dubai</Link>
    <Link style={{padding: "10px"}} to="/annual-maintenance-contracts-dubai">AMC in dubai</Link>
    <Link style={{padding: "10px"}} to="/amc-packages-dubai-annual-maintenance-contract">AMC Packages Dubai Annual Maintenance Contract</Link>
   
    <Link style={{padding: "10px"}} to="/Indoor Plant Maintenance Service (AMC) in Dubai"> Indoor Plant Maintenance Service (AMC) in Dubai</Link>
    <Link style={{padding: "10px"}} to="/Annual Maintenance Contract Dubai AMC Services in UAE">Annual Maintenance Contract Dubai | AMC Services in UAE </Link>
    <Link style={{padding: "10px"}} to="/Garden Maintenance AMC Services at Discounted Prices">Garden Maintenance AMC Services at Discounted Prices </Link>
    <Link style={{padding: "10px"}} to="/Dubai Landscape Maintenance - Beautiful Gardens Made Easy">Dubai Landscape Maintenance - Beautiful Gardens Made Easy </Link>
    <Link style={{padding: "10px"}} to="/Garden Maintenance AMC Services at Best Offers">Garden Maintenance AMC Services at Best Offers </Link>
    <Link style={{padding: "10px"}} to="/Looking For AMC for Your Lawn? Garden Maintenance Services">Looking For AMC for Your Lawn? | Garden Maintenance Services
    </Link>
    <Link style={{padding: "10px"}} to="/Garden Maintenance Services Landscaping Services in Dubai"> Garden Maintenance Services | Landscaping Services in Dubai 
    </Link>
    <Link style={{padding: "10px"}} to="/Annual Indoor Plant Maintenance Plant care service Dubai"> Annual Indoor Plant Maintenance #1 Plant care service Dubai
    </Link>


    <Link style={{padding: "10px"}} to="/Landscaping Companies in Dubai - Landscaping in Dubai"> Landscaping Companies in Dubai - Landscaping in Dubai
    </Link>
    <Link style={{padding: "10px"}} to="/Landscaping Company Dubai Landscaping designer"> Landscaping Company Dubai | Landscaping designer
    </Link>
    <Link style={{padding: "10px"}} to="/Best Landscaping Company in Dubai"> Best Landscaping Company in Dubai
    </Link>
    <Link style={{padding: "10px"}} to="/Landscaping Dubai | Landscaping Company Dubai"> Landscaping Dubai | Landscaping Company Dubai 
    </Link>
    <Link style={{padding: "10px"}} to="/Desert Group | Best and Quality Landscaping Company in Dubai"> Desert Group | Best and Quality Landscaping Company in Dubai
    </Link>
    <Link style={{padding: "10px"}} to="/Premium Landscaping Company in Dubai"> Premium Landscaping Company in Dubai 
    </Link>
    <Link style={{padding: "10px"}} to="/Best in Dubai Landscapes | Best UAE Landscaping"> Best in Dubai Landscapes | Best UAE Landscaping
    </Link>
    <Link style={{padding: "10px"}} to="/Landscaping Services Dubai"> Landscaping Services Dubai
    </Link>
    <Link style={{padding: "10px"}} to="/Landscaping Services | Garden Landscaping Company in Dubai"> Landscaping Services | Garden Landscaping Company in Dubai
    </Link>
    <Link style={{padding: "10px"}} to="/Your Top Landscaping Company in Dubai | Planters"> Your Top Landscaping Company in Dubai | Planters
    </Link>
    <Link style={{padding: "10px"}} to="/Best landscape architecture in Dubai - Outdoor Living"> Best landscape architecture in Dubai - Ducon Outdoor Living
    </Link>
    <Link style={{padding: "10px"}} to="/Landscaping and Gardening services company – Dubai"> Landscaping and Gardening services company – Dubai
    </Link>
    <Link style={{padding: "10px"}} to="/Landscaping Services | Dubai | UAE"> Landscaping Services | Dubai | Abu Dhabi | UAE
    </Link>
    <Link style={{padding: "10px"}} to="/One of the best landscaping companies in Dubai"> One of the best landscaping companies in Dubai
    </Link>
    <Link style={{padding: "10px"}} to="/Best Landscaping Company in Dubai | UAE"> Best Landscaping Company in Dubai | UAE
    </Link>
    <Link style={{padding: "10px"}} to="/Gardening Services in Dubai | Top Landscaping & Gardening"> Gardening Services in Dubai | Top Landscaping & Gardening
    </Link>


    
    <Link style={{padding: "10px"}} to="/benefits-of-annual-maintenance-contracts-for-your-garden-plant-in-dubai-with-garden-oasis-landscape-service"> Benefits of Annual Maintenance Contracts for Your Garden Plant in Dubai with Garden Oasis Landscape Service
    </Link>



    <Link style={{padding: "10px"}} to="/get-high-quality-artificial-grass-and-transform-your-outdoor-space-at-our-store"> 
   
    Get High-Quality Artificial Grass And Transform Your Outdoor Space At Our Store </Link>
    <Link style={{padding: "10px"}} to="/what-is-landscape-design"> 
    What is Landscape Design

    </Link>





    <Link style={{padding: "10px"}} to="/garden-maintenance-services-dubai"> Garden Maintenance Services Dubai
    </Link>
    <Link style={{padding: "10px"}} to="/top-rated-landscaping-and-plant-maintenance-services-dubai"> Top-Rated Landscaping and Plant Maintenance Services Dubai

    </Link>
    <Link style={{padding: "10px"}} to="/premium-garden-maintenance-services-dubai"> Premium Garden Maintenance Services Dubai

    </Link>
    <Link style={{padding: "10px"}} to="/best-landscaping-and-gardening-services-company-in-dubai"> Best Landscaping & Gardening Services Company in Dubai

    </Link>
    <Link style={{padding: "10px"}} to="/garden-landscaping-dubai-expert-garden-care"> Garden Landscaping Dubai - Expert Garden Care

    </Link>
    <Link style={{padding: "10px"}} to="/book-professional-garden-maintenance-services-in-dubai"> Book Professional Garden Maintenance Services In Dubai

    </Link>
    <Link style={{padding: "10px"}} to="/garden-landscaping-dubai-trusted-#1-gardeners-in-dubai">Garden Landscaping Dubai | Trusted #1 Gardeners in Dubai
 
    </Link>
    <Link style={{padding: "10px"}} to="/dubai-best-landscaping-and-garden-maintenance"> Dubai Best Landscaping And Garden Maintenance

    </Link>
    <Link style={{padding: "10px"}} to="/your-top-landscaping-company-in-dubai"> Your Top Landscaping Company in Dubai 

    </Link>
    <Link style={{padding: "10px"}} to="/top-indoor-plants-maintenance-service-dubai"> Top Indoor Plants Maintenance Service Dubai 

    </Link>
    <Link style={{padding: "10px"}} to="/landscape-and-gardening-works-and-maintenance-in-dubai"> Landscape & Gardening works & Maintenance in Dubai

    </Link>
    <Link style={{padding: "10px"}} to="/affordable-gardening-services-in-dubai"> Affordable Gardening Services in Dubai

    </Link>
    <Link style={{padding: "10px"}} to="/landscaping-and-gardening-services-company-dubai"> Landscaping and Gardening services company – Dubai

    </Link>
    <Link style={{padding: "10px"}} to="/urban-landscapes-luxury-landscaping-design-dubai"> Urban Landscapes | Luxury Landscaping Design Dubai

    </Link>
    <Link style={{padding: "10px"}} to="/best-dubai-landscape-dubai-top-landscaping-firm"> Best Dubai Landscape | Dubai's top landscaping firm

    </Link>
    <Link style={{padding: "10px"}} to="/one-of-the-best-landscaping-companies-in-dubai"> One of the best landscaping companies in Dubai

    </Link>
    <Link style={{padding: "10px"}} to="/premium-landscaping-company-in-dubai"> Premium Landscaping Company in Dubai

    </Link>
    <Link style={{padding: "10px"}} to="/best-choice-landscape-gardening-llc-dubai"> Best Choice Landscape Gardening LLC Dubai

    </Link>
    <Link style={{padding: "10px"}} to="/landscaping-in-dubai-greenlawn-landscaping-and-gardening"> Landscaping in Dubai | Greenlawn Landscaping & gardening

    </Link>
    <Link style={{padding: "10px"}} to="/best-landscaping-and-lawn-service-in-dubai"> Best Landscaping And Lawn Service In Dubai

    </Link>
    <Link style={{padding: "10px"}} to="/expert-garden-landscaping-in-dubai-for-your-outdoor-oasis"> Expert Garden Landscaping in Dubai for Your Outdoor Oasis

    </Link>
    <Link style={{padding: "10px"}} to="/best-gardening-companies-landscaping-work-in-dubai"> Best Gardening Companies Landscaping Work in Dubai

    </Link>
    <Link style={{padding: "10px"}} to="/dubai-landscaping-home-office-landscape-indoor-outdoor-#1"> Dubai Landscaping | Home office Landscape Indoor Outdoor #1

    </Link>
    <Link style={{padding: "10px"}} to="/best-landscaping-companies-in-dubai-villa-garden"> Best Landscaping Companies in Dubai-Villa Garden

    </Link>
    <Link style={{padding: "10px"}} to="/landscaping-services-in-uae-landscaping-company-in-dubai"> Landscaping Services in UAE: Landscaping Company in Dubai

    </Link>
    <Link style={{padding: "10px"}} to="/#1-swimming-pool-and-landscaping-company-in-dubai-uae"> #1 Swimming Pool and Landscaping Company in Dubai, UAE 
    </Link>
    {/* <Link style={{padding: "10px"}} to="/"> 
    </Link> */}
    {/* <Link style={{padding: "10px"}} to="/"> 
    </Link> */}
    {/* <Link style={{padding: "10px"}} to="/"> 
    </Link> */}
    {/* <Link style={{padding: "10px"}} to="/"> 
    </Link> */}
    {/* <Link style={{padding: "10px"}} to="/"> 
    </Link> */}
    {/* <Link style={{padding: "10px"}} to="/"> 
    </Link> */}
    {/* <Link style={{padding: "10px"}} to="/"> 
    </Link> */}
    {/* <Link style={{padding: "10px"}} to="/"> 
    </Link> */}
   
  </p>

            </div>
            </div>
            </div>






              
              {/* End .blog-posts */}
            </main>
            
            {/* End .footer */}
          </div>
          <button id="scroll-top" title="Back to Top">
            <i className="icon-arrow-up" />
          </button>
          {/* Mobile Menu */}
          
          {/* <div className="container newsletter-popup-container mfp-hide" id="newsletter-popup-form">
            <div className="row justify-content-center">
              <div className="col-10">
                <div className="row no-gutters bg-white newsletter-popup-content">
                  <div className="col-xl-3-5col col-lg-7 banner-content-wrap">
                    <div className="banner-content text-center">
                      <img src="assets/images/popup/newsletter/logo.png" className="logo" alt="logo" width={60} height={15} />
                      <h2 className="banner-title">get
                        <span>25</span>
                        <mark>%</mark> off</h2>
                      <p>Subscribe to the Molla eCommerce newsletter to receive timely updates from your favorite products.</p>
                      <form action="#">
                        <div className="input-group input-group-round">
                          <input type="email" className="form-control form-control-white" placeholder="Your Email Address" aria-label="Email Adress" required />
                          <div className="input-group-append">
                            <button className="btn" type="submit">
                              <span>go</span>
                            </button>
                          </div>
                        </div>
                      </form>
                        <div className="custom-control custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="register-policy-2" required />
                        <label className="custom-control-label" htmlFor="register-policy-2">Do not show this popup again</label>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2-5col col-lg-5 ">
                    <img src="assets/images/popup/newsletter/img-1.jpg" className="newsletter-img" alt="newsletter" width={700} height={400} />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        )
    
}

export default Home;
import React, { useState , useEffect } from 'react';
import {Link ,useHistory} from "react-router-dom"
// import FacebookIcon from '@material-ui/icons/Facebook';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import SendIcon from '@material-ui/icons/Send';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
// import { Flare } from '@material-ui/icons';
// import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import logo1 from '../assets/images/logo-kar2.png';
import payment from '../asset1/images/payments.png';

// import FacebookIcon from '@mui/icons-material/Facebook';
  const Footer =(props)=> {
    const [MainCatogories , setMainCategories] = useState([])
    useEffect(() =>{

    fetch("https://garden-oasis-landscapping-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res3=>res3.json())
        .then(res4=>{
            let dat = []
            let dat1 = []
            res4.map((res55,c)=>{
              if(c < 10){
                dat.push(res55)
                dat1.push(0)

              }
            })
                        console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCategories(data)

        })
      },[])

  //  history = useHistory();
  
        return (
            <>
          <footer className="footer footer-2 font-weight-normal second-primary-color" style={{backgroundColor: '#222'}}>
              
              {/* End .cta */}
              <div className="container">
                <hr className="mt-0 mb-0" style={{borderColor: '#444'}} />
              </div>
              <div className="footer-middle border-0">
                <div className="container">
                  <div className="row">
                    
                    {/* End .col-sm-12 col-lg-3 */}


                    <div className="col-12 col-lg-2-5cols">
                      <div className="widget widget-about mb-4">
                        <img src={logo1} className="footer-logo" alt="Footer Logo" width={105} height={25} style={{
                             width: "200px"}}/>
                        <p className="font-weight-normal second-primary-color" style={{paddingLeft:"10px"}}>Discover a whole new world of greenery and gardening at Garden Oasis Landscaping! 🌺 Located in the heart of Dubai, we offer a unique online experience where you can explore and purchase a wide range of indoor and outdoor plants, gardening accessories, equipment, seeds, and fertilizers.

With over 25 years of experience in the industry. Whether you're a seasoned gardener or just starting out, finding the perfect plants and accessories for your space is now easier than ever.
                         </p>
                        <div className="widget-about-info" style={{paddingLeft:"10px"}}>
                          <div className="row">
                            <div className="col-sm-6 col-md-6">
                              <span className="widget-about-title text-white">Got Question? Call us 24/7</span>
                              <a href="tel:+971509480695" className="text-primary">+971 509480695</a>
                            </div>
                            {/* End .col-sm-6 */}
                            {/* <div className="col-sm-6 col-md-6">
                              <span className="pl-3 widget-about-title text-white">Payment Method</span>
                              <figure className="pl-3 mb-0 footer-payments">
                                <img src={payment} alt="Payment" width={272} height={20} />
                              </figure>
                            </div> */}
                            {/* End .col-sm-6 */}
                          </div>
                          {/* End .row */}
                        </div>
                        {/* End .widget-about-info */}
                      </div>
                      {/* End .widget about-widget */}
                    </div>
                    



                    <div className="col-sm-3 col-lg-5cols" style={{paddingLeft:"10px"}}>
                      <div className="widget mb-4" style={{paddingLeft:"10px"}}>
                        <h4 className="widget-title text-white">Information</h4>
                        {/* End .widget-title */}
                        <ul className="widget-list">
                          <li>
                            <Link to="/about">About Us</Link>
                          </li>
                          <li>
                            <Link to="/contact">Contact Us</Link>
                          </li>
                          <li>
                            <Link to="/cart">View Cart</Link>
                          </li>
                          <li>
                            <Link to="/whislist">My Wishlist</Link>
                          </li>
                          
                          <li>
                            <Link to="/FAQ">Help</Link>
                          </li>
                          <li>
                            <Link to="/sitemap">Sitemap</Link>
                          </li>
                        </ul>
                        {/* End .widget-list */}
                      </div>
                      {/* End .widget */}
                    </div>
                    {/* End .col-sm-4 col-lg-3 */}
                  
                    {/* End .col-sm-4 col-lg-3 */}
                    <div className="col-sm-3 col-lg-5cols" style={{paddingLeft:"10px"}}>
                      <div className="widget mb-4" style={{paddingLeft:"10px"}}>
                        <h4 className="widget-title text-white">QUICK LINKS</h4>
                        {/* <h4 className="widget-title text-white">My Account</h4> */}
                        {/* End .widget-title */}
                        <ul className="widget-list">
                        
                          <li>
                            <Link to="/term-policy">Terms and conditions</Link>
                          </li>
                          <li>
                            <Link to="/Shipping">Return & Shipping</Link>
                          </li>
                          <li>
                            <Link to="/privacy">Privacy Policy</Link>
                          </li>
                          
                        </ul>
                        
                        {/* End .widget-list */}
                      </div>
                      {/* End .widget */}
                    </div>
                    <div className="col-sm-3 col-lg-5cols" style={{paddingLeft:"10px"}}>
                      <div className="widget mb-4" style={{paddingLeft:"10px"}}>
                        <h4 className="widget-title text-white">Categories</h4>
                        {/* <h4 className="widget-title text-white">My Account</h4> */}
                        {/* End .widget-title */}
                        <ul className="widget-list">
                        
                        {MainCatogories.map((res,i)=>{
                                      if(res.MainCategories  === "(AMC) Annual Maintenance Contract"){

                   return (
                <>
                    <li>
                      <a href={"/categories-main/"+"Annual Maintenance Contract"}>
                         {res.MainCategories}</a>
                    </li>
                 </>
              
              )      
            }
            else if(res.MainCategories  === "Landscaping"){
              return (
           <>
               <li>
                 <a href={"/categories-main/"+res.MainCategories}>
                    {res.MainCategories}</a>
               </li>
            </>
         
         )      

             
            
            }
            else{
              return (
           <>
               <li>
                 <a href={"/categories/"+res.MainCategories+"/all-products" }>
                    {res.MainCategories}</a>
               </li>
            </>
              )
            }
          
  })
                        }

                        </ul>
                        
                        {/* End .widget-list */}
                      </div>
                      {/* End .widget */}
                    </div>
                    {/* End .col-sm-64 col-lg-3 */}

                    
                  </div>
                  {/* End .row */}
                </div>
                {/* End .container */}
              </div>
              {/* End .footer-middle */}
              <div className="footer-bottom font-weight-normal">
                <div className="container">
                  <p className="font-weight-normal ml-lg-2 second-primary-color" style={{fontSize : "13px", fontWeight :"500"}}>Copyright © 2024 Garden Oasis Landscape. All Rights Reserved. Design by <a href="https:www.webocreators.com" style={{color :"white",fontSize:"17px"}}>Webo Creators</a> </p>
                  {/* End .footer-copyright */}
                  {/* <ul className="footer-menu justify-content-center">
                    <li>
                      <a href="#">Terms Of Use</a>
                    </li>
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                  </ul> */}
                  {/* End .footer-menu */}
                  <div className="social-icons social-icons-color justify-content-center">
                    <span className="social-label">Social Media</span>
                    <a href="https://www.facebook.com/gol.flowers.uae" className="social-icon social-facebook" title="Facebook" target="_blank">
                      <i className="icon-facebook-f" />
                    </a>
                    {/* <a href="#" className="social-icon social-twitter" title="Twitter" target="_blank">
                      <i className="icon-twitter" />
                    </a> */}
                    <a href="https://www.instagram.com/gol.flowers_uae" className="social-icon social-instagram" title="Instagram" target="_blank">
                      <i className="icon-instagram" />
                    </a>
                    {/* <a href="https://www.youtube.com/channel" className="social-icon social-youtube" title="Youtube" target="_blank">
                      <i className="icon-youtube" />
                    </a> */}
                  </div>
                  {/* End .soial-icons */}
                </div>
                {/* End .container */}
              </div>
              {/* End .footer-bottom */}
            </footer>
            
         
            </>
        )
    
}

export default Footer;







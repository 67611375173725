import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Ico from './iconBox';

// import aboutBlog from '../assets/images/blog_1.jpg';
// import person1 from '../assets/images/person_1.jpg';
// import person2 from '../assets/images/person_2.jpg';
// import person3 from '../assets/images/person_3.jpg';
// import person4 from '../assets/images/person_4.jpg';

class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)

    }
    render() {
        return (
            <div>
            
            {/* End Main Top */}
            {/* Start Top Search */}
            
            {/* End Top Search */}
            {/* Start All Title Box */}
            
            {/* End All Title Box */}
            {/* Start About Page  */}





            <div className="page-header text-center" style={{backgroundImage: 'url("assets/images/page-header-bg.jpg")'}}>
            <div className="container">
              <h1 className="page-title">ABOUT US</h1>
            </div>{/* End .container */}
          </div>{/* End .page-header */}
          <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="index-32.html">Home</a></li>
                <li className="breadcrumb-item"><a href="#">About Us</a></li>
              </ol>
            </div>{/* End .container */}
          </nav>{/* End .breadcrumb-nav */}



        

<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-lg-8 col-sm-12" style={{margin  :"auto"}}>


            {/* <h4  style={{marginBottom : "1px"}}>Home Appliances</h4> */}
            <h3>About Garden Oasis Landscaping: Creating Your Green Sanctuary in Dubai</h3>
            <p>At Garden Oasis Landscaping, we're dedicated to bringing the beauty of nature into your home space in Dubai. With a diverse selection of plants and garden accessories, we aim to create your own oasis of tranquility and greenery. With over 25 years of experience in the industry, our passionate team is committed to helping you transform your space into a vibrant and thriving garden paradise. Whether you're a seasoned gardener or just starting out, we're here to provide expert guidance and top-quality products to enhance your gardening experience. Explore our range and let your imagination bloom with Garden Oasis Landscaping.




</p>
<br />
  <h5>Our Mission:</h5>
  <p>At Garden Oasis Landscaping, we are on a mission to bring nature's beauty into every home in Dubai. With our diverse selection of plants and garden accessories, we aim to inspire and empower our customers to create their own green sanctuaries.</p>

<br />
  <h5>Expertise and Experience:</h5>
  <p>With over 25 years of experience in the industry, Garden Oasis Landscaping boasts a team of passionate experts dedicated to providing the highest quality plants and accessories. Our team's wealth of knowledge ensures that you receive expert guidance and support every step of the way.</p>

<br />
  <h5>Product Categories:</h5>
  <ol>
    <li><strong>Indoor Plants:</strong> Bring the beauty of nature indoors with our selection of lush greenery and vibrant blooms.</li>
    <li><strong>Outdoor Plants:</strong> Transform your outdoor space into a thriving oasis with our diverse range of outdoor plants suitable for Dubai's climate.</li>
    <li><strong>Garden Accessories:</strong> Enhance your gardening experience with our collection of high-quality accessories, including pots, planters, tools, and more.</li>
    <li><strong>Seeds and Fertilizers:</strong> Start your gardening journey from scratch with our premium seeds and fertilizers, carefully selected to ensure optimal growth and health of your plants.</li>
  </ol>

<br />
  <h5>Transform Your Space:</h5>
  <p>Whether you're a seasoned gardener or a novice enthusiast, Garden Oasis Landscaping is here to help you transform your space into a vibrant and thriving garden paradise. Explore our range today and let your imagination bloom with Garden Oasis Landscaping.</p>

            </div>
            </div>
            </div>


            <div className="container icon-boxes-section">
                <div className="icon-boxes-container py-4 bg-lighter mb-2 mt-2">

                  <div>
                 
                 < Ico/>
                

                  </div>

                </div>
                </div>



            {/* </div> */}
        
          </div>
        )
    }
}

export default About;